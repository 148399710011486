<template>
  <company-layout>
    <template v-slot:main>
      Roller
    </template>
  </company-layout>
</template>
<script>
import companyLayout from "@/layouts/CompanyLayout.vue";
export default {
  components: {
    companyLayout,
  },
};
</script>
